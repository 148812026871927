/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react'
import { connect } from "react-redux";
import { BuddyTabContainer } from '../../PeopleHomeV2/styles';
import { IconWrapper } from '../../ChallengeDetailsV2/styles';
import HoverButton from '../../common/HoverButtonV2';
import PropTypes from "prop-types";
import { ImageUrl } from '../../../utils/constants';
import { addCompanyInitiatives, fetchCompanyInfo } from '../../../redux/actions';
import { getCompanyInitiatives, getTopWellnessInitiatives } from '../../../redux/actions/companyDashboard';
import Waiting from '../../Waiting';
import { AddInitiativePriority, GetAllInitiatives } from '../../../redux/constants/apiConstants';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../utils/config';
import { ButtonContainer, CustomRadioButton } from '../../CreateEvent/style';
import CommonButton from '../../common/CommonButton/CommonButton';
import { t } from 'i18next';
import { LaunchEventIcon, closeIcon } from '../../../utils/icons';
import { fetchApi } from '../../../utils/methods';
import { Border } from '../../EventDetails/styles';

class CustomizeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.companyInfo.id,
      // priorityInitiatives: [],
      allInitiatives: [],
      selectedInitiatives: []
    }
  }

  componentDidMount(){
    const {getCompanyInitiatives,getTopWellnessInitiatives /*, location */} = this.props;
    const currentYear = new Date().getFullYear();
    getCompanyInitiatives(this.state.companyId);
    getTopWellnessInitiatives(this.state.companyId,currentYear)
    // this.fetchPriorityInitiatives(this.state.companyId);
    // this.fetchPriorityInitiatives();
    this.fetchAllCompanyInitiatives();
  }

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" fillOpacity={1}/>
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#6099b4"
        />
      </g>
    </svg>
  );

  goBack = () => {
    this.props.history.push("/company/health")
  }

  headerView = () => (
    <BuddyTabContainer marginBottom="25px" width="1248px" gap="12px" pLeft="8px" style={{marginTop:"25px",alignItems:"center", width:"1248px"}}>
      <IconWrapper margin="auto 0px auto 0">
        <HoverButton
          title="Back"
          width={"24px"}
          height="24px"
          svgPath={closeIcon("#007AB1")} 
          onClick={() => this.goBack() }
          backgroundColor={"#005C870D"}
          backgroundColorActive={"#007AB1"}
          marginLeft={"0"}
        />
      </IconWrapper>
      <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px', lineHeight:"24px", margin:"auto auto auto 0px"}}>{"Customize your dashboard."}</p>
    </BuddyTabContainer>
      
  );


  // fetchPriorityInitiatives = async(companyId)=>{
  //   const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //   let url =
  //   BASE_URL + GetInitiativePriority + "/" + companyId;
  //   await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
  //     (res) => {
  //       res.json().then((data) => {
  //         window.console.log("All initiatives: ", data.data);
  //       }).catch((error)=>{
  //         toast.error(error.message);
  //       });
  //     }
  //   ).catch((error)=>{
  //     toast.error(error.message);
  //   });
  // };


  handleSelectInitiative = (item) => {
    const { selectedInitiatives } = this.state;
    let newSelectedInitiatives = [...selectedInitiatives];
  
    if (newSelectedInitiatives.includes(item.id)) {
      newSelectedInitiatives = newSelectedInitiatives.filter((selectedId) => selectedId !== item.id);
    } else if (newSelectedInitiatives.length < 3) {
      newSelectedInitiatives.push(item.id);
    } else {
      toast.error("You can select up to 3 initiatives only");
    }
  
    this.setState({ selectedInitiatives: newSelectedInitiatives });
  };

  handleAddPriorityInitiative = () => {
    const {selectedInitiatives, companyId} = this.state;
    const entity = selectedInitiatives?.map((initiative_id) => ({
      initiative_id,
      company_id: companyId
    }));

    // window.console.log("entity: ", entity);
    this.PostEvent(entity);
  }

  PostEvent = async(payload)=>{
    
    try{

      const res = await fetchApi(AddInitiativePriority, "POST",payload);
      window.console.log("res: ", res);

      if(res.message){
        toast.error(res.message);
        // this.setState({
        //   buttonStatus: false,
        // });
      }else{
        toast.success(res.data[0]);
        this.props.history.push({pathname:"/company/health",state:"updateData"})
      }

    }catch(error){
      toast.error(error)

    }
  }

  fetchAllCompanyInitiatives = async() => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
    BASE_URL + GetAllInitiatives;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
      (res) => {
        res.json().then((data) => {
          window.console.log("All initiative: ", data.data[0]);
          this.setState({
            allInitiatives: data.data[0]
          })
        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    });
  }

  render() {
    const {companyInitiatives,wellnessInitiatives} = this.props;
    const { selectedInitiatives, allInitiatives} = this.state;
    window.console.log("wellnessInitiatives: ", wellnessInitiatives);
    window.console.log("selectedInitiatives: ", selectedInitiatives);
    window.console.log("allInitiatives: ", allInitiatives);
    if(companyInitiatives === null || wellnessInitiatives === null){
      <Waiting/>
    }

    
    return (
      <div>
        {this.headerView()}
        {/* <BuddyTabContainer style={{marginTop:"25px",alignItems:"center", borderBottom: '1px solid rgba(0, 92, 135, 0.6)', borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px"}}>
          <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px',marginTop:'11px', paddingLeft: '15px'}}>Choose up to 3 priority health initiatives below to personalize your dashboard.</p>
        </BuddyTabContainer> */}
        <BuddyTabContainer style={{marginTop:"25px",alignItems:"flex-start", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", width: "1248px", display: 'flex', flexDirection: 'column'}}>
          <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px',marginTop:'11px', paddingLeft: '4px', marginBottom: '0px'}}>Choose up to 3 priority health initiatives below to personalize your dashboard.</p>
          <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'0px 0px', padding: '0px', width: "99%"}}/>
        </BuddyTabContainer>
        <div style={{width:'1248px',background:'white',height:'auto',margin:"auto",marginBottom:"200px",padding:'12px',display:"flex",flexWrap:'wrap',gap: '12px'}} >
          {allInitiatives !== null && allInitiatives.length > 0 ? allInitiatives.map((item,index) => <div key={index} style={{width:"400px",height:"84px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'15px',borderRadius:'6px',border:"1px solid #b3cfdb",position:'relative',cursor:"pointer" }}>
            <div style={{width:'230px',display:'flex',alignItems:'center',gap:"15px"}}>
              <img src={ImageUrl+"/"+item.initiative_image} alt="" style={{width:"60px",height:"60px",borderRadius:"6px"}}/>
              <div>
                <p style={{width:'240px',color:"#005c87",fontFamily:'rubik-medium',fontSize:'16px',marginBottom:0, display:"flex"}}>{item.initiative_name}</p>
                {/* <p style={{color:"#669db7",fontFamily:'rubik',fontSize:'14px',marginBottom:0}}>{(item.is_upcoming && (new Date(item.launch_date) > new Date())) ? "Upcoming" : "Active"} {(!item.is_upcoming || (new Date(item.launch_date) <= new Date()) )  && "| " + item.points + " Pts"}  {(item.is_upcoming && (new Date(item.launch_date) > new Date())) ? "| " + (Math.ceil((Number(new Date(item.launch_date) - new Date())/ (1000 * 60 * 60 * 24))) + " Days to start") : ""}</p> */}
              </div>
            </div>
            {/* <div style={{width:'72px',height:'38px',borderRadius:'6px',background: '#f2f7f9',color:'#005c87',fontFamily:'rubik-medium',fontSize:'16px',display:'flex',alignItems:'center',justifyContent:'center', border: ''}}>{item.percentage}%</div> */}
            <span style={{color: selectedInitiatives.includes(item.id) ? "#FFFFFF" : ""}}>
              <CustomRadioButton
                onClick={() => this.handleSelectInitiative(item)}
                background="#afcbd3"
                style={{background: selectedInitiatives.includes(item.id) ? '#007AB1' : "", fontFamily:"Rubik-Medium", fontSize:"18px", lineHeight:"18px"}}
                marginR="0px"
              >
                {" "}
                {selectedInitiatives.includes(item.id) ? selectedInitiatives.indexOf(item.id) + 1 : ""}
                {" "}
              </CustomRadioButton>
            </span>
        
          </div>) : null
          }
        </div>
        <ButtonContainer>
          <div className="wrapper">
            <CommonButton
              btnType={"squareIcon"}
              disabled={this.state.selectedInitiatives.length < 3}
              styles={{color:"#007AB1", marginAuto:'auto'}}
              onClick={() => this.handleAddPriorityInitiative()}
              title={t("Submit")}
              nextIcon={LaunchEventIcon()}
            />
          </div>
        </ButtonContainer>
      </div>
    )
  }
}

CustomizeDashboard.propTypes = {
  history: PropTypes.object,
  wellnessInitiatives: PropTypes.array,
  companyInitiatives: PropTypes.array,
  addCompanyInitiatives: PropTypes.func,
  getTopWellnessInitiatives:PropTypes.func,
  companyId:PropTypes.any,
  companyInfo : PropTypes.any,
  getCompanyInitiatives:PropTypes.func,
  location:PropTypes.object,
  setPage:PropTypes.func,
};

const mapStateToProps = (state) => ({
  wellnessInitiatives: state.companyDashboard.wellnessInitiatives,
  companyInitiatives: state.companyDashboard.companyInitiatives,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyInitiatives: (companyId) => dispatch(getCompanyInitiatives(companyId)),
  getTopWellnessInitiatives : (companyId,year) => dispatch(getTopWellnessInitiatives(companyId,year)),
  addCompanyInitiatives: (data, companyId, year) => dispatch(addCompanyInitiatives(data, companyId, year)),
  fetchCompanyInfo: (companyId) => dispatch(fetchCompanyInfo(companyId)),
});
  

export default connect(mapStateToProps,mapDispatchToProps)(CustomizeDashboard)